/* eslint-disable no-unused-expressions */
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../assets/stylesheets/newStyles.scss'

export const HomeLayoutQuery = graphql`
  query HomeLayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`

const Comment = (props) => {
  return <comment dangerouslySetInnerHTML={{ __html: `<!-- ${props.text} -->` }} />
}

function HomeLayout({ children, lang}) {
  return (
    <StaticQuery 
      query={HomeLayoutQuery} 
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet>
            <title>{siteMetadata.title}</title>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="title" content={siteMetadata.title} />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />
            <meta property="og:title" content={siteMetadata.title} />
            <meta property="og:description" content={siteMetadata.description} />
            <meta property="twitter:title" content={siteMetadata.title} />
            <meta property="twitter:description" content={siteMetadata.description} />
            <meta 
              property="twitter:url" 
              content={`https://trailhead.salesforce.com/${lang}/career-path/`} 
            />
            <script src={`${siteMetadata.tdsPath}/scripts/th-element-loader.js`}></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />
          </Helmet>
          
          <div id="career-site" className="contentArea">
            <Comment text="Migrated App"/>
            {children}
          </div>
        </>
      )}
    />
  )
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default HomeLayout