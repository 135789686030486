import React from 'react'
import HomeLayout from '../layouts/HomeLayout'
import I18n from '../utils/I18n'
import Quotes from '../components/Quotes'
import allCareers from "../../data/careers.json"

const HomeTemplate = (props) => {
  const {pageContext: {data, lang}} = props
  const i18n = new I18n({lang})
  const s3AssetsPath = process.env.GATSBY_S3_PATH
  
  const homePageEN = data.find(item => item.lang === 'en')
  const homePage = data.find(item => item.lang === lang)
  
  const careerEN = allCareers.filter(item => item.lang === 'en')
  const careersLang = allCareers.filter(item => item.lang === lang)

  const home = homePage ? homePage : homePageEN;
  const {hero, stats, roles, quotes, community, resources, accelerate_your_career, opportunities, footer} = home
  const promo = home.promo ? home.promo : homePageEN.promo;
  
  let careers = []

  careerEN.forEach(career => {
    const careerLang = careersLang.find(c => c.slug === career.slug)
    careers.push(careerLang ? careerLang : career)
  })

  careers = careers.sort((a, b) => (!a.slug || a.card_order > b.card_order) ? 1 : -1)

  return (
    <HomeLayout lang={lang}>
      <tm-page class="theme--salesforce">
        <tm-hero-a 
          key={`hero-section`}
          background={hero.background}
          cta-href={hero.cta_href} 
          cta-label={hero.cta_label} 
          cta-target={hero.target}
          img-alt={hero.image_alt} 
          img-src={`${s3AssetsPath}/${hero.img_src}`} 
          img-align={hero.image_align}
          subtitle={hero.subtitle} 
          title={hero.title}>
        </tm-hero-a>

        <tm-section-a key={`stats`} background={stats.background} title={stats.title} description={stats.description}>
          <tm-stats-a class="slds-p-top_xx-large">
            {stats.items.length > 0 && stats.items.map((stat, i) => {
              return (
                <tm-stat-item key={`stat-${i}`} description={stat.description} title={stat.title}></tm-stat-item>
              );
            })}
          </tm-stats-a>
        </tm-section-a>

        <tm-section-a
          key={`role-list`}
          background={roles.background}
          title={roles.title}
          description={roles.description}>
            <div className={`slds-container_center slds-container_large slds-p-top_xx-large`}>
            <tm-promo-c class="slds-p-bottom_xx-large" title={promo.title} description={promo.description} img-src={promo.img_src} img-alt={promo.img_alt} primary-cta-title={promo.cta_title} primary-cta-href={promo.cta_href} primary-cta-target={promo.cta_target} background={promo.background}></tm-promo-c>
              <tm-card-grid-a img-aspect="100%" img-type="left" class="slds-m-bottom_large">
                {careers.map((career, i) => {
                  if(career.display_card !== false) {
                    const salary = career.sections.stats.items.find(i => i.use_in_hub === true) || ''
                    return (
                      <tm-grid-item 
                        key={`findYourRole-${i}`}
                        pretitle={career.card_pretitle}
                        pretitle-href={`/${lang}/career-path/${career.slug}`}
                        pretitle-href-text={salary.title}
                        title={career.card_title}
                        description={career.card_description} 
                        cta-href={`/${lang}/career-path/${career.slug}`}
                        cta-target={false}
                        cta-title={i18n.text('homepage_role_cta_title')}
                        img-alt={career.card_image_alt !== '' ? career.card_image_alt : career.card_title}
                        img-src={career.card_image}
                      />
                    )
                  }
                })}
              </tm-card-grid-a>
            </div>
        </tm-section-a>

        <Quotes key={`section-quotes`} background={quotes.background} quotes={quotes} />

        <tm-section-a background={community.background}>
          <tm-feature-grid-a>
            <tm-grid-item 
              img-src={community.img_src}
              img-alt={community.img_alt}
              title={community.title}
              description={community.description}
              cta-primary-title={community.cta_primary_title}
              cta-primary-href={community.cta_primary_href} 
              cta-secondary-title={community.cta_secondary_title}
              cta-secondary-href={community.cta_secondary_target} />
          </tm-feature-grid-a>
        </tm-section-a>

        <tm-section-a 
          key={`section-resources`} 
          background={resources.background} 
          title={resources.title}>

          <tm-card-grid-a img-aspect="60%" img-type="full">
            {resources.items.map((item, i) => {
              const target = item.cta_target ? item.cta_target : null
              return (
                <tm-grid-item 
                  key={`resource-${i}`} 
                  title={item.title}
                  description={item.description}
                  cta-href={item.cta_href} 
                  cta-target={target}
                  cta-title={item.cta_title}
                  img-alt={item.img_alt ? item.img_alt : `${item.title} Image`} 
                  img-src={item.img_src}
                  ></tm-grid-item>
              )
            })}
          </tm-card-grid-a>
        </tm-section-a>

        <tm-section-a background={accelerate_your_career.background}>
          <tm-feature-grid-a>
            <tm-grid-item 
              img-src={accelerate_your_career.img_src}
              img-alt={accelerate_your_career.img_alt}
              title={accelerate_your_career.title}
              description={accelerate_your_career.description}
              cta-primary-title={accelerate_your_career.cta_primary_title}
              cta-primary-href={accelerate_your_career.cta_primary_href} 
              cta-secondary-title={accelerate_your_career.cta_secondary_title}
              cta-secondary-href={accelerate_your_career.cta_secondary_target} />
          </tm-feature-grid-a>
        </tm-section-a>

        <tm-end-cap-a
          key={`section-endcap`} 
          background="dark" 
          title={opportunities.title} 
          subtitle={opportunities.subtitle}
          cta-href={opportunities.cta_href}
          cta-label={opportunities.cta_label}
          cta-target={opportunities.cta_target}
          img-alt={opportunities.image_alt}
          img-src={opportunities.img_src} 
        />

        <tm-footnote>
          <div dangerouslySetInnerHTML={{ __html: footer }}></div>
        </tm-footnote>
      </tm-page>
    </HomeLayout>
  )
}

export default HomeTemplate